import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Login, Logout, Shop2, Store } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Badge, Divider, Drawer, ListItemIcon } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { useTranslation } from 'react-i18next';

import Cart from './customer/components/Cart';
import Search from './customer/components/Search';
import ProductsMenu from './customer/components/ProductsMenu';
import { updateCustomer } from '../redux/userHandle';
import yourLogo from '../assets/my-logo.png';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: '#FF4A4B',
  [theme.breakpoints.down('sm')]: {
    padding: '0.5rem 0',
  }
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    width: '100%',
  }
}));

const Logo = styled('img')({
  width: '150px',
  height: 'auto',
  marginRight: '1rem'
});

const NavigationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

const MobileMenuButton = styled(IconButton)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    marginRight: '1rem'
  }
}));

const MobileDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    width: '250px',
    padding: '1rem'
  }
});

const Navbar = () => {
  const { currentUser, currentRole } = useSelector(state => state.user);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [language, setLanguage] = React.useState('en');
  const [isCartOpen, setIsCartOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElSign, setAnchorElSign] = React.useState(null);

  const totalQuantity = currentUser?.cartDetails?.reduce((total, item) => total + item.quantity, 0) || 0;

  React.useEffect(() => {
    if (currentRole === "Customer") {
      dispatch(updateCustomer(currentUser, currentUser.id));
    }
  }, [currentRole, currentUser, dispatch]);

  const handleLanguageToggle = (event) => {
    const newLanguage = event.target.checked ? 'ne' : 'en';
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  const renderMobileMenu = () => (
    <MobileDrawer
      anchor="left"
      open={isMobileMenuOpen}
      onClose={() => setIsMobileMenuOpen(false)}
    >
      <Box sx={{ padding: '1rem' }}>
        <Search />
        <ProductsMenu dropName={t('navbar.categories')} />
        <ProductsMenu dropName={t('navbar.products')} />
        {currentRole === null && (
          <>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 2 }}
              onClick={() => navigate("/Customerlogin")}
            >
              {t('navbar.customerLogin')}
            </Button>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 1 }}
              onClick={() => navigate("/Sellerlogin")}
            >
              {t('navbar.sellerLogin')}
            </Button>
          </>
        )}
      </Box>
    </MobileDrawer>
  );

  return (
    <StyledAppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <MobileMenuButton
            color="inherit"
            onClick={() => setIsMobileMenuOpen(true)}
          >
            <MenuIcon />
          </MobileMenuButton>

          <LogoContainer onClick={() => navigate("/")}>
            <Logo src={yourLogo} alt="Logo" />
            <Typography variant="h6" sx={{ display: { xs: 'none', sm: 'block' } }}>
              {t('navbar.samarpan')}
            </Typography>
          </LogoContainer>

          <NavigationContainer sx={{ flexGrow: 1 }}>
            <Search />
            <ProductsMenu dropName={t('navbar.categories')} />
            <ProductsMenu dropName={t('navbar.products')} />
          </NavigationContainer>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Switch
              checked={language === 'ne'}
              onChange={handleLanguageToggle}
              color="default"
            />

            {currentRole === "Customer" && (
              <>
                <Tooltip title="Cart">
                  <IconButton onClick={() => setIsCartOpen(true)} color="inherit">
                    <Badge badgeContent={totalQuantity} color="error">
                      <ShoppingCartIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>

                <Tooltip title="Account">
                  <IconButton onClick={(e) => setAnchorElUser(e.currentTarget)} color="inherit">
                    <Avatar sx={{ width: 32, height: 32, bgcolor: "#8970dc" }}>
                      {currentUser?.name?.charAt(0)}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </>
            )}

            {currentRole === null && (
              <Button
                color="inherit"
                onClick={(e) => setAnchorElSign(e.currentTarget)}
                sx={{ display: { xs: 'none', md: 'block' } }}
              >
                {t('navbar.signIn')}
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>

      {renderMobileMenu()}
      
      {/* User Menu */}
      <Menu
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={() => setAnchorElUser(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => navigate("/Profile")}>
          <Avatar />
          <Link to="/Profile">{t('navbar.profile')}</Link>
        </MenuItem>
        <MenuItem onClick={() => navigate("/Orders")}>
          <ListItemIcon>
            <Shop2 fontSize="small" />
          </ListItemIcon>
          <Link to="/Orders">{t('navbar.myOrders')}</Link>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate("/Logout")}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <Link to="/Logout">{t('navbar.logout')}</Link>
        </MenuItem>
      </Menu>

      {/* Sign In Menu */}
      <Menu
        anchorEl={anchorElSign}
        open={Boolean(anchorElSign)}
        onClose={() => setAnchorElSign(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => navigate("/Customerlogin")}>
          <Avatar />
          <Link to="/Customerlogin">{t('navbar.customerLogin')}</Link>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate("/Sellerlogin")}>
          <ListItemIcon>
            <Store fontSize="small" />
          </ListItemIcon>
          <Link to="/Sellerlogin">{t('navbar.sellerLogin')}</Link>
        </MenuItem>
      </Menu>

      {/* Cart Drawer */}
      <Drawer
        anchor="right"
        open={isCartOpen}
        onClose={() => setIsCartOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: { xs: '100%', sm: '400px' }
          }
        }}
      >
        <Cart setIsCartOpen={setIsCartOpen} />
      </Drawer>
    </StyledAppBar>
  );
};

export default Navbar;